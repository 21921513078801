<template>
  <div class="row">
    <div class="col-12 bg-pink">
      <card class="mb-2">
        <div class="row align-items-center">
          <div class="col-8 col-lg-11">
            <h5 class="h3">{{ getTrans("messages.widget") }}</h5>
          </div>
          <div class="col-4 col-lg-1">
            <el-button type="primary" size="small" @click="tools">{{
              getTrans("messages.back")
            }}</el-button>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row block align-items-center">
            <div class="col-12">
              <span class="title text-bold">{{
                getTrans("messages.script_tag_header")
              }}</span>
              <p disabled>{{ getTrans("messages.widget_sub_1") }}</p>
              <span>{{ getTrans("messages.widget_script_1") }}</span>
              <div class="form-group mt-2">
                <form>
                  <label>{{ getTrans("messages.integration_script") }}</label>
                  <div class="alert alert-info">
                    <!-- eslint-disable -->
                    <code class="code-block text-white"
                      >&lt;script type="text/javascript"&gt; <br />var _diva =
                      _diva || [];
                      <br />
                      _diva['setAccount'] = 66;<br />
                      _diva['Widget'] = [];<br />
                      _diva['Widget']['options'] = [];<br />
                      <span v-if="datavalues.gender"
                        >_diva['gender'] = "{{ datavalues.gender }}"<br
                      /></span>
                      _diva['Widget']['options']['class']='diva_widget';<br />
                      >&lt;/script&gt;<br />
                      &lt;script id="diva_services"
                      src="//diva.services/services"
                      type="text/javascript"&gt;&lt;/script&gt;</code
                    >
                    <!-- eslint-enable -->
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <span>{{ getTrans("messages.widget_settings") }}</span>
              <ToolOptionsSelector
                @updated="updated"
                :showModules="[
                  'camsites',
                  'profiles',
                  'tracking',
                  'gender',
                  'thumbnail',
                ]"
              ></ToolOptionsSelector>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <span>{{ getTrans("messages.widget_code") }}</span>
              <p>{{ getTrans("messages.widget_code_text") }}</p>
              <div class="container-fluid">
                <div class="row align-items-center">
                  <div class="col-6 col-lg-2">
                    <p>{{ getTrans("messages.banner_rectangle") }}</p>
                  </div>
                  <div class="col-6 col-lg-2">
                    <el-button
                      class="m-2"
                      type="primary"
                      size="md"
                      @click="modals.rectangleModal = true"
                      >{{ getTrans("messages.preview") }}</el-button
                    >
                  </div>
                  <div class="col-12">
                    <modal :show="modals.rectangleModal">
                      <h6 class="modal-title" id="banner-rectangle-modal">
                        {{ getTrans("messages.preview") }}
                      </h6>
                    </modal>
                  </div>
                </div>
                <div class="alert alert-info">
                  <!-- eslint-disable -->
                  <code class="code-block text-white"
                    >&lt;div class="diva_widget" data-theme="theme1"
                    data-size="{{ datavalues.thumbnail }}"
                    <span v-if="datavalues.profiles"
                      >data-models="{{ datavalues.profiles }}"</span
                    >
                    <span v-if="datavalues.camsites"
                      >data-platforms="{{ datavalues.camsites }}"</span
                    ><span v-if="datavalues.tracking"
                      ><span
                        v-for="(sub, name) in datavalues.tracking"
                        :key="name"
                        >data-{{ name }}="{{ sub }}"
                      </span></span
                    >
                    data-width="300px" data-height="250px"&gt;&lt;/div&gt;</code
                  >
                  <!-- eslint-enable -->
                </div>
              </div>
              <div class="container-fluid">
                <div class="row align-items-center">
                  <div class="col-6 col-lg-2">
                    <p>{{ getTrans("messages.banner_small_rectangle") }}</p>
                  </div>
                  <div class="col-6 col-lg-2">
                    <el-button
                      class="m-2"
                      type="primary"
                      size="md"
                      @click="modals.smallrectangleModal = true"
                      >{{ getTrans("messages.preview") }}</el-button
                    >
                  </div>
                  <div class="col-12">
                    <modal :show="modals.smallrectangleModal">
                      <template v-slot:header>
                        <h6
                          class="modal-title"
                          id="banner-small-rectangle-modal"
                        >
                          {{ getTrans("messages.preview") }}
                        </h6>
                      </template>
                    </modal>
                  </div>
                </div>
                <div class="alert alert-info">
                  <!-- eslint-disable -->
                  <code class="code-block text-white"
                    >&lt;div class="diva_widget" data-theme="theme1"
                    data-size="{{ datavalues.thumbnail }}"
                    <span v-if="datavalues.profiles"
                      >data-models="{{ datavalues.profiles }}"</span
                    >
                    <span v-if="datavalues.camsites"
                      >data-platforms="{{ datavalues.camsites }}"</span
                    ><span v-if="datavalues.tracking"
                      ><span
                        v-for="(sub, name) in datavalues.tracking"
                        :key="name"
                        >data-{{ name }}="{{ sub }}"
                      </span></span
                    >data-width="180px"
                    data-height="150px"&gt;&lt;/div&gt;</code
                  >
                  <!-- eslint-enable -->
                </div>
              </div>
              <div class="container-fluid">
                <div class="row align-items-center">
                  <div class="col-6 col-lg-2">
                    <p>{{ getTrans("messages.banner_skyscraper") }}</p>
                  </div>
                  <div class="col-6 col-lg-2">
                    <el-button
                      class="m-2"
                      type="primary"
                      size="md"
                      @click="modals.skyscraperModal = true"
                      >{{ getTrans("messages.preview") }}</el-button
                    >
                  </div>
                  <div class="col-12">
                    <modal :show="modals.skyscraperModal">
                      <template v-slot:header>
                        <h6 class="modal-title" id="banner-skyscraper-modal">
                          {{ getTrans("messages.preview") }}
                        </h6>
                      </template>
                    </modal>
                  </div>
                </div>
                <div class="alert alert-info">
                  <!-- eslint-disable -->
                  <code class="code-block text-white"
                    >div class="diva_widget" data-theme="theme1" data-size="{{
                      datavalues.thumbnail
                    }}"
                    <span v-if="datavalues.profiles"
                      >data-models="{{ datavalues.profiles }}"</span
                    >
                    <span v-if="datavalues.camsites"
                      >data-platforms="{{ datavalues.camsites }}"</span
                    ><span v-if="datavalues.tracking"
                      ><span
                        v-for="(sub, name) in datavalues.tracking"
                        :key="name"
                        >data-{{ name }}="{{ sub }}"
                      </span></span
                    >data-height="600px"&gt;&lt;/div&gt;</code
                  >
                  <!-- eslint-enable -->
                </div>
              </div>
              <div class="container-fluid">
                <div class="row align-items-center">
                  <div class="col-6 col-lg-2">
                    <p>{{ getTrans("messages.banner_large_leaderboard") }}</p>
                  </div>
                  <div class="col-6 col-lg-2">
                    <el-button
                      class="m-2"
                      type="primary"
                      size="md"
                      @click="modals.largeleaderboardModal = true"
                      >{{ getTrans("messages.preview") }}</el-button
                    >
                  </div>
                  <div class="col-12">
                    <modal :show="modals.largeleaderboardModal">
                      <template v-slot:header>
                        <h6
                          class="modal-title"
                          id="banner-large-leaderboard-modal"
                        >
                          {{ getTrans("messages.preview") }}
                        </h6>
                      </template>
                    </modal>
                  </div>
                </div>
                <div class="alert alert-info">
                  <!-- eslint-disable -->
                  <code class="code-block text-white"
                    >&lt;div class="diva_widget" data-theme="theme1"
                    data-size="{{ datavalues.thumbnail }}"
                    <span v-if="datavalues.profiles"
                      >data-models="{{ datavalues.profiles }}"</span
                    >
                    <span v-if="datavalues.camsites"
                      >data-platforms="{{ datavalues.camsites }}"</span
                    ><span v-if="datavalues.tracking"
                      ><span
                        v-for="(sub, name) in datavalues.tracking"
                        :key="name"
                        >data-{{ name }}="{{ sub }}"
                      </span></span
                    >data-width="971px"
                    data-height="150px"&gt;&lt;/div&gt;</code
                  >
                  <!-- eslint-enable -->
                </div>
              </div>
              <div class="container-fluid">
                <div class="row align-items-center">
                  <div class="col-12 text-center">
                    <p class="mr-2">{{ getTrans("messages.custom_size") }}:</p>
                  </div>
                  <div class="col-12 text-center">
                    <label class="mr-2"
                      >{{ getTrans("messages.height") }}:</label
                    >
                    <el-input
                      class="mr-2 mb-2"
                      v-model="heightInput"
                      type="number"
                      style="width: 150px"
                      min="100px;"
                      max="1000px;"
                      minlength="100px;"
                      maxlength="1000px;"
                    ></el-input>
                  </div>
                  <div class="col-12 text-center">
                    <label class="mr-2"
                      >{{ getTrans("messages.width") }}:</label
                    >
                    <el-input
                      class="mr-2"
                      v-model="widthInput"
                      type="number"
                      style="width: 150px"
                      min="100px;"
                      max="1000px;"
                      minlength="100px;"
                      maxlength="1000px;"
                    ></el-input>
                  </div>
                  <div class="col-12 text-center">
                    <el-button
                      class="m-2"
                      type="primary"
                      size="md"
                      @click="modals.customsizeModal = true"
                      >{{ getTrans("messages.preview") }}</el-button
                    >
                  </div>
                  <div class="col-12">
                    <modal :show="modals.customsizeModal">
                      <template v-slot:header>
                        <h6 class="modal-title" id="customsize-modal">
                          {{ getTrans("messages.preview") }}
                        </h6>
                      </template>
                    </modal>
                  </div>
                </div>
                <div class="alert alert-info mt-2">
                  <!-- eslint-disable -->
                  <code class="code-block text-white"
                    >&lt;div class="diva_widget" data-theme="theme1"
                    data-size="{{ datavalues.thumbnail }}"
                    <span v-if="datavalues.profiles"
                      >data-models="{{ datavalues.profiles }}"</span
                    >
                    <span v-if="datavalues.camsites"
                      >data-platforms="{{ datavalues.camsites }}"</span
                    ><span v-if="datavalues.tracking"
                      ><span
                        v-for="(sub, name) in datavalues.tracking"
                        :key="name"
                        >data-{{ name }}="{{ sub }}"
                      </span></span
                    >
                    data-width="{{ widthInput }}px" data-height="{{
                      heightInput
                    }}px"&gt;&lt;/div&gt;</code
                  >
                  <!-- eslint-enable -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <span>{{ getTrans("messages.widget_documentation") }}</span>
              <p>{{ getTrans("messages.widget_docu_text") }}</p>
              <div class="d-flex">
                <el-table :data="tableData" border style="width: 100%">
                  <div class="col-4">
                    <el-table-column
                      prop="attributename"
                      label="Attribute name"
                    >
                    </el-table-column>
                  </div>
                  <div class="col-4">
                    <el-table-column prop="description" label="Description">
                    </el-table-column>
                  </div>
                  <div class="col-4">
                    <el-table-column prop="values" label="Values">
                    </el-table-column>
                  </div>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </card>
      <!-- END OF CONTENT -->
    </div>
  </div>
</template>
<script>
// Components
import { mapGetters } from "vuex";
import Modal from "@/components/Modal";
import ToolsMixin from "./ToolsMixin";
import ToolOptionsSelector from "./components/ToolOptionsSelector";
import {
  ElButton,
  ElRadio,
  ElCollapse,
  ElCollapseItem,
  ElInput,
  ElButtonGroup,
  ElRadioButton,
  ElTable,
  ElTableColumn as ElTableColumn,
} from "element-plus";
export default {
  name: "empty-page",
  mixins: [ToolsMixin],
  components: {
    Modal,
    ToolOptionsSelector,
    [ElButton.name]: ElButton,
    [ElCollapse.name]: ElCollapse,
    [ElCollapseItem.name]: ElCollapseItem,
    [ElInput.name]: ElInput,
    [ElRadio.name]: ElRadio,
    [ElRadioButton.name]: ElRadioButton,
    [ElButtonGroup.name]: ElButtonGroup,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      gender: "All",
      modals: {
        rectangleModal: false,
        smallrectangleModal: false,
        skyscraperModal: false,
        largeleaderboardModal: false,
        customsizeModal: false,
      },
      heightInput: "200",
      widthInput: "200",
      genderactive: "All",
      activeNames: [],
      selectedCustomTracking: [],
      selectedCamsite: "",
      currentSearch: "",
      loading: false,
      datavalues: { profiles: "", thumbnail: "m" },
      tableData: [
        {
          attributename: "data-width",
          description: "The width in pixels",
          values: "100px-1000px",
        },
        {
          attributename: "data-height",
          description: "The height in pixels",
          values: "100px-1000px",
        },
        {
          attributename: "data-size",
          description: "Thumbnail sizes",
          values: "xs, s, m, l, xl (Leave empty for auto)",
        },
        {
          attributename: "data-platforms",
          description:
            "Name of camsites to show. Comma seperator and capital first letter of name",
          values: "	LiveJasmin, Chaturbate",
        },
        {
          attributename: "data-gender",
          description: "Which gender to prioritize",
          values: "	f=Female, m=Male, t=Trans, c=Couples",
        },
        {
          attributename: "data-theme",
          description: "Which theme to use",
          values: "theme1 (More coming soon)",
        },
      ],
    };
  },
  methods: {
    tools() {
      this.$router.push({ name: "Tools" });
    },
    setGender(value) {
      this.gender = value;
    },
    setSize(value) {
      this.size = value;
    },
    setFallbackOption(value) {
      this.fallbackoption = value;
    },
    closeCollapse() {
      this.activeNames = [];
    },
    nextCollapse() {
      this.activeNames = Number(this.activeNames) + 1 + "";
    },
    selectedCamsitesUpdated(camsites) {
      if (Array.isArray(camsites)) {
        var selected = camsites
          .map((c) => {
            return c.title;
          })
          .join(",");
        if (selected == '""') {
          selected = "";
        }
        this.datavalues.camsites = selected;
      } else {
        this.datavalues.camsites = "";
      }
    },
    selectedProfilesUpdated(profiles) {
      if (Array.isArray(profiles) && profiles.length) {
        this.datavalues.profiles = profiles
          .map((p) => {
            return p.id;
          })
          .join(",");
      } else {
        this.datavalues.profiles = "";
      }
    },
    selectedCustomTrackingUpdated(customtracking) {
      this.selectedCustomTracking = customtracking;
    },
    updated(options) {
      this.selectedCamsitesUpdated(options.camsites);
      this.selectedProfilesUpdated(options.profiles);
      this.datavalues.tracking = options.tracking;
      this.datavalues.settings = options.settings;
      this.datavalues.gender = options.gender;
      this.datavalues.thumbnail = options.thumbnail;
      this.$forceUpdate();
    },
  },
  computed: {
    ...mapGetters("Camsite", ["getCamsites"]),
    ...mapGetters("Profile", { getProfiles: "getList" }),

    filteredProfiles() {
      var prof = this.getProfiles
        ? this.getProfiles.filter((p) => {
            var correctCamsite =
              this.selectedCamsite && p.performer
                ? p.performer.data.platform_id == this.selectedCamsite
                : true;
            var correctName =
              this.currentSearch && p.performer
                ? p.performer.data.name
                    .toLowerCase()
                    .indexOf(this.currentSearch.toLowerCase()) >= 0
                : true;
            return correctCamsite && correctName;
          })
        : [];
      return prof;
    },
  },
  mounted() {
    this.$store.dispatch("Camsite/get");
    this.$store.dispatch("Profile/getAll");
  },
};
</script>
<style></style>
