<script>
export default {
  methods: {
    run_script() {
      window._diva = window._diva || [];
      var _diva = window._diva;
      _diva["setAccount"] = "'" + this.$store.state.user.id + "'";
      _diva["Widget"] = [];
      _diva["Widget"]["options"] = [];
      _diva["Widget"]["options"]["studio_id"] =
        "'" + this.$store.state.user.managedStudio.data.id + "'";
      _diva["Widget"]["options"]["class"] = "diva_widget";

      // Remove the script tag if it exists
      let script = document.getElementById("script-tag");
      if (script) {
        this.$refs["script_container"].removeChild(script);
      }

      // Same for the iframe
      while (this.$refs["preview_container"].firstChild) {
        this.$refs["preview_container"].removeChild(
          this.$refs["preview_container"].firstChild
        );
      }

      // Create a new script tag
      script = document.createElement("script");
      script.setAttribute("id", "script-tag");
      script.setAttribute("src", "//diva.services/services");
      this.$refs["script_container"].appendChild(script);
    }
  }
};
</script>
